import React from 'react';
import './App.css';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	// Link,
	// useHistory
} from "react-router-dom";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import VPlan from './components/VPlan';
import Navigation from './components/Navigation';
import Login from './components/Login';
import Start from './components/Start';

export const API_URL = process.env.REACT_APP_API_URL || "https://vertretungsplan-api.justus-d.de" // "http://10.200.0.222:8080"

function App() {
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
	
	const theme = React.useMemo(
		() =>
			createTheme({
			palette: {
				type: prefersDarkMode ? 'dark' : 'light',
			},
		}),
		[prefersDarkMode],
	);

	return (
		<div>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Router>
					<Navigation />
					<div style={{height: "56px", width: "100%"}} />
					<Switch>
						<Route path="/login">
							<Login />
						</Route>
						<Route path="/vplan/filter/:fClass">
							<VPlan />
						</Route>
						<Route path="/vplan">
							<VPlan />
						</Route>
						<Route path="/install">
							<div>Installieren</div>
						</Route>
						<Route path="/settings">
							<div>Settings</div>
						</Route>
						<Route path="/">
							{/* <Start /> */}
							<div style={{
								display: "grid",
								alignItems: "center",
								justifyContent: "center",
								minHeight: "100vh"
							}}>
								<div>Access Denied</div>
							</div>
						</Route>
					</Switch>
				</Router>
			</ThemeProvider>
		</div>
	);
}

export default App;
