import React from "react";
import "./Sub.css";

import Paper from "@material-ui/core/Paper";

interface sub {
	lesson: string,
	classes: string,
	newTeacher: string,
	newSubject: string,
	newRoom: string,
	text: string,
	teacher: string,
	subject: string,
	room: string
}

export default function Sub(props: {sub?: sub|null}) {
	const sub = props.sub

	if(!sub) {
		return (
			<Paper>
				<div className="Sub-root" style={{
					backgroundColor: "rgb(100, 100, 100)"
				}}>
					<div style={{
						padding: "17px",
						fontSize: "16px"
					}}>Keine Vertretungen</div>
				</div>
			</Paper>
		)
	}

	sub.classes = sub.classes.replace("&nbsp;", " ")
	sub.lesson = sub.lesson.replace("&nbsp;", " ")
	sub.newRoom = sub.newRoom.replace("&nbsp;", " ")
	sub.newSubject = sub.newSubject.replace("&nbsp;", " ")
	sub.newTeacher = sub.newTeacher.replace("&nbsp;", " ")
	sub.room = sub.room.replace("&nbsp;", " ")
	sub.subject = sub.subject.replace("&nbsp;", " ")
	sub.teacher = sub.teacher.replace("&nbsp;", " ")
	sub.text = sub.text.replace("&nbsp;", " ")

	return (
		<Paper>
			{/* root div */}
			<div className="Sub-root" style={{
				backgroundColor: (sub.newSubject === "---") ? "#f44236": "#2196f3"
			}}>
				{/* lesson div */}
				<div className="Sub-lesson">
					<span className="Sub-lesson">{sub.lesson}</span>
				</div>
				{/* newLesson new newRoom room div */}
				<div className="Sub-changes">
					<div>
						{(sub.newSubject === "---") ? "Entfall": "Vertretung"}
					</div>
					<div>
						{sub.newSubject === "---" ? <>
							{sub.subject} ({sub.teacher}) in {sub.room}</> : <>
							{sub.newSubject} ({sub.newTeacher}) in {sub.newRoom}{sub.text !== "&nbsp;" ? " – "+sub.text : null}</>
						}
					</div>
				</div>
			</div>
		</Paper>
	)
}
