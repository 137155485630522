import React, { useState } from "react";

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import GetAppIcon from '@material-ui/icons/GetApp';
import SettingsIcon from '@material-ui/icons/Settings';
import HomeIcon from '@material-ui/icons/Home';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory } from "react-router";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
		},
		menuButton: {
			marginRight: theme.spacing(2),
		},
		title: {
			flexGrow: 1,
		},
	}),
)

const drawerStyles = makeStyles({
	list: {
		width: 250,
	},
	fullList: {
		width: 'auto',
	},
});

export default function Navigation() {

	const history = useHistory()

	const classes = useStyles()
	const drawerClasses = drawerStyles()
	const [drawerOpen, setDrawerOpen] = useState(false)

	return (
		<div>
			<AppBar position="fixed">
				<Toolbar>
					<IconButton
						edge="start"
						className={classes.menuButton}
						color="inherit"
						aria-label="menu"
						onClick={() => {
							setDrawerOpen(true)
						}}
					>
						<MenuIcon />
					</IconButton>
					<Typography variant="h6" className={classes.title}>
						Vertretungsplan
					</Typography>
					{/* <Button color="inherit"></Button> */}
				</Toolbar>
			</AppBar>
			<Drawer
				anchor="left"
				open={drawerOpen}
				onClose={() => {
					setDrawerOpen(false)
				}}
				className={drawerClasses.fullList}
			>
				<div className={drawerClasses.list}>
					<List>
						<ListItem button key="a" onClick={() => {
							history.push("/");
							setDrawerOpen(false)
						}}>
							<ListItemIcon>
								<HomeIcon />
							</ListItemIcon>
							<ListItemText primary="Start" />
						</ListItem>
						<ListItem button key="b" onClick={() => {
							history.push("/install")
							setDrawerOpen(false)
						}}>
							<ListItemIcon>
								<GetAppIcon />
							</ListItemIcon>
							<ListItemText primary="Installieren" />
						</ListItem>
						<ListItem button key="c" onClick={() => {
							history.push("/settings")
							setDrawerOpen(false)
						}}>
							<ListItemIcon>
								<SettingsIcon />
							</ListItemIcon>
							<ListItemText primary="Einstellungen" />
						</ListItem>
						<ListItem button key="d" onClick={() => {
							history.push("/settings")
							setDrawerOpen(false)
						}}>
							<ListItemIcon>
								<SettingsIcon />
							</ListItemIcon>
							<ListItemText primary="Einstellungen" />
						</ListItem>
						<Divider />
						<ListItem button key="e" onClick={() => {
							history.push("/vplan/filter/Jg.13")
							setDrawerOpen(false)
						}}>
							<ListItemIcon>
								<DashboardIcon />
							</ListItemIcon>
							<ListItemText primary="Filter: Jg.13" />
						</ListItem>
					</List>
				</div>
			</Drawer>
		</div>
	)
}