import React, { useEffect, useState } from "react";
import { API_URL } from "../App";

import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory, useParams } from "react-router";
import Paper from "@material-ui/core/Paper";
import Sub from "./Sub";

interface day {
	dateString?: string,
	motd?: Array<Array<string>>,
	subs?: Array<{
		lesson: string,
		classes: string,
		newTeacher: string,
		newSubject: string,
		newRoom: string,
		text: string,
		teacher: string,
		subject: string,
		room: string
	}>
}

interface week {
	weekNumber: number,
	lastModified: string,
	days: Array<day>
}

export default function VPlan() {

	const { fClass } = useParams<any>()
	const history = useHistory()
	const [loading, setLoading] = useState<boolean>(true)
	const [weeks, setWeeks] = useState<Array<week>|null>(null)

	useEffect(() => {
		let authHeader = localStorage.getItem("vplan-authHeader") || ""
		fetch(`${API_URL}/getVPlan`, {
			headers: {
				"Authorization": authHeader
			}
		}).then((r) => {
			switch (r.status) {
				case 200:
					r.json().then((data) => {
						setWeeks(data)
						localStorage.removeItem("vplan-weeks")
						localStorage.setItem("vplan-weeks", JSON.stringify(data || "[]"))
						setLoading(false)
					})
					break;
				case 401:
					history.push("/login")
					break;
				default:
					break;
			}
		}).catch((e) => {
			console.log(e)
		})
	}, [history])

	
	if (!localStorage["vplan-authHeader"]) {
		history.push("/login")
		return null
	}

	if (loading) {
		return (
			<div style={{margin: "0 auto", width: "200px", padding: "32px", textAlign: "center"}}>
				<CircularProgress />
			</div>
		)
	}
	if (!weeks) {
		return (
			<div style={{margin: "0 auto", maxWidth: "300px", padding: "16px"}}>Keine Daten.</div>
		)
	}
	let fWeeks: Array<week> = weeks
	return (
		<div style={{
			margin: "0 auto",
			maxWidth: "800px",
			padding: "16px"
		}}>
			{fWeeks.map((week) => {
				return week.days.map((day) => {
					let fDayEmpty = true
					day.subs?.forEach((sub) => {
						if (fClass === sub.classes) {
							fDayEmpty = false
						}
					})
					return (
						<div>
							<h2>{day.dateString}</h2>
							<div>
								{day.subs?.map((sub, iSub) => {
									if (fClass) {
										if (fClass !== sub.classes) {
											return null
										}
									}
									return (
										<div key={iSub} style={{
											marginBottom: "8px"
										}}>
											<Sub sub={sub} />
										</div>
									)
								})}
								{(day.subs?.length === 0) || fDayEmpty ? <Sub /> : null }
							</div>
						</div>
					)
				})
			})}
		</div>
	)
}
