import React, { useState } from "react";
import { useHistory } from "react-router";
import "./Login.css";
import { API_URL } from "../App";

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			'& > *': {
				margin: theme.spacing(1),
				// width: '25ch',
			},
		},
	}),
);

export default function Login() {

	const [user, setUser] = useState("")
	const [pass, setPass] = useState("")
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)

	const classes = useStyles()
	const history = useHistory()

	async function sendLogin(user: string, pass: string) {
		let authHeader = `Basic ${ btoa(`${user}:${pass}`) }`
		let r = await fetch(`${API_URL}/checkLogin`, {
			headers: {
				"Authorization": authHeader
			}
		})
		const p = new Promise((resolve, reject) => {
			if (r.status === 200) {
				localStorage.setItem("vplan-authHeader", authHeader)
				history.push("/")
				resolve(true)
			} else {
				reject(false)
			}
		})
		return p
	}

	return (
		<div className="Login-root">
			<Paper elevation={3} style={{padding: "16px"}}>
				<h1 style={{textAlign: "center"}}>Login</h1>
				<form noValidate autoComplete="off">
					<TextField
						className="Login-input"
						id="user"
						name="user"
						label="Benutzername"
						type="text"
						variant="outlined"
						fullWidth
						style={{
							paddingBottom: "16px"
						}}
						onChange={(e) => {
							setUser(e.target.value)
							setError(false)
						}}
						disabled={loading}
						error={error}
					/>
					<TextField
						className="Login-input"
						id="pass"
						name="pass"
						label="Passwort"
						type="password"
						variant="outlined"
						fullWidth
						style={{
							paddingBottom: "16px"
						}}
						onChange={(e) => {
							setPass(e.target.value)
							setError(false)
						}}
						disabled={loading}
						error={error}
					/>
					<Button
						variant="contained"
						color="primary"
						type="submit"
						fullWidth
						onClick={(e) => {
							e.preventDefault()
							setLoading(true)
							sendLogin(user, pass)
								.then((p) => {
									setLoading(false)
								})
								.catch((p) => {
									setLoading(false)
									setError(true)
								})
						}}
						disabled={loading}
					>Speichern</Button>
				</form>
			</Paper>
		</div>
	)
}
